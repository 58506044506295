icon = (code, options = {}) ->
  options.class = ['material-icons', options.size, options.class].join ' '
  delete options.size
  $("<i/>", options).html(code)[0].outerHTML

fileIcon = (filename, options = {}) ->
  extension = String(filename).toLowerCase().split('.').pop()
  [code, icon_class] = if CamBackend.fileIcons.hasOwnProperty(extension)
    CamBackend.fileIcons[extension]
  else
    CamBackend.fileIcons.other

  options.class = [icon_class, options.class].join ' '
  icon(code, options)

window.CamBackend.fileIcons = {
  "pdf": ["picture_as_pdf", "text-danger"],
  "png": ["image", "text-danger"],
  "jpg": ["image", "text-danger"],
  "gif": ["image", "text-danger"],
  "bmp": ["image", "text-danger"],
  "other": ["insert_drive_file", "text–info"]
}

window.CamBackend.icon = icon
window.CamBackend.fileIcon = fileIcon

import template from 'lodash/template'

window.CamBackend =
  activateBootstrapSelect: ->
    $('.selectpicker').each (index, element) ->
      $el = $(element)
      $el.selectpicker()
      $("label[for=\"#{$el.attr('id')}\"]").on 'click', ->
        $el.selectpicker('toggle')

  activateDatePicker: (element) ->
    $(element).datetimepicker
      format: 'DD.MM.YYYY'
      locale: "#{$('html').attr('lang')}-ch"
      icons:
        time:     'material-icons material-icons-access_time'
        date:     'material-icons material-icons-today'
        up:       'material-icons material-icons-keyboard-arrow-up'
        down:     'material-icons material-icons-keyboard-arrow-down'
        previous: 'material-icons material-icons-keyboard-arrow-left'
        next:     'material-icons material-icons-keyboard-arrow-right'
        today:    'material-icons material-icons-today'
        clear:    'material-icons material-icons-delete'
        close:    'material-icons material-icons-close'

  activateDatePickers: ->
    $('.datepicker').each (index, element) => this.activateDatePicker(element)

  showConfirmationDialog: (element, confirmCallbackMethod) ->
    options =
      title: element.data('confirm')
      textCancel: element.data('cancel-text') || CamBackend.t('actions', 'cancel')
      textConfirm: element.data('confirm-text') || element.data('original-title') || CamBackend.t('actions', 'ok')
      body: if (selector = element.data('confirm-body-selector')) then template($(selector).html()) else undefined
    $template = $(CamBackend.renderTemplate 'confirmDialog', options)
    $template.find('[data-cancel-button]').on 'click', ->
      $template.modal('hide')
    $template.find('[data-confirm-button]').on 'click', ->
      $template.modal('hide')
      if confirmCallbackMethod
        confirmCallbackMethod()
      else
        $.rails.confirmed(element)
    $template.modal('show')

require('./strings')
require('./templates')
require('./icon')
require('./document_modal')
require('./global')

AVATAR_SIZE_LARGE = 130

fetchOrCreateImage = (imgContainer, initials) ->
  $imgContainer = $(imgContainer)
  $img = $imgContainer.children('img:first')
  if $img.length == 1
    $img.after(initials)
    return $img
  $('<img/>').css('display', 'none').prependTo($imgContainer).after(' ') # Trick.

initPictureUpload = ->
  $el = $('[data-picture-upload]')
  $preview = $('[data-picture-preview]')
  initials = $preview.data('picture-initials')
  $upload = $el.find('[type=file]:first')
  $remove = $el.find('[data-picture-remove]')
  $hidden = $($remove.attr('href'))
  $img = fetchOrCreateImage($preview.find('span'), initials)
  $upload.change ->
    $remove.show()
    if @files && @files[0] && FileReader
      $hidden.val('')
      reader = new FileReader()
      reader.onload = (e) ->
        $img[0].src = e.target.result
        $img[0].onload = ->
          $img.css(height: 'auto', width: 'auto').show()
          vertical = $img[0].naturalHeight > $img[0].naturalWidth
          $img.css
            position: 'absolute'
            height: if vertical then 'auto' else '100%'
            width: if vertical then '100%'  else 'auto'
            left: '50%'
            top: '50%'
            transform: 'translate(-50%, -50%)'
      reader.readAsDataURL(@files[0])

  $remove.click (event) ->
    event.preventDefault()
    $(this).hide().closest('form').get(0).reset()
    $hidden.val(1)
    $img.hide()
    $img[0].src = ''


if Turbolinks? then $(document).on('turbolinks:load', initPictureUpload) else $(initPictureUpload)

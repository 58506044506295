IMAGE_EXTENSIONS = ['png', 'jpg', 'gif', 'bmp', 'jpeg']
CamBackend.templates.documentModal = """
  <div class="document-modal">
    <div class="document-modal__dimmer" data-document-modal="close"></div>
    <div class="document-modal__header">
      <h4 class="document-modal__title pull-left">
        <%= CamBackend.fileIcon(filename, { class: 'left' }) %>
        <%- title %>
      </h4>
      <div class="document-modal__toolbar btn-group pull-right">
        <a href="<%- url %>"
          title="<%= CamBackend.t('actions', 'download') %>"
          class="btn btn-just-icon btn-simple"
          target="_blank"
          download="<%- filename %>"
          data-toggle="tooltip"
          data-placement="bottom">
          <%= CamBackend.icon('file_download') %>
        </a>
        <a href="#"
          title="<%= CamBackend.t('actions', 'close') %>"
          class="btn btn-just-icon btn-simple"
          data-document-modal="close"
          data-toggle="tooltip"
          data-placement="bottom">
          <%= CamBackend.icon('close') %>
        </a>
      </div>
    </div>

    <div class="document-modal__content"></div>

    <% if(hasNext) { %>
      <div class="document-modal__next btn black">
        <%= CamBackend.icon('chevron_right', { class: 'white-text' }) %>
      </div>
    <% } %>

    <% if(hasPrev) { %>
      <div class="document-modal__prev btn black">
        <%= CamBackend.icon('chevron_left', { class: 'white-text' }) %>
      </div>
    <% } %>
  </div>
"""

CamBackend.templates.noPreview = """
  <div class="no-preview">
    <p class="text-center text-muted">
      <%= CamBackend.icon('visibility_off', { size: 'large' }) %>
    </p>
    <a href="<%- url %>" target="_blank" class="btn btn-primary truncate">
      <%= CamBackend.icon('file_download', { class: 'left' }) %>
      <%- title %>
    </a>
  </div>
"""

nextFileCard = ($fileCard) ->
  $fileCard.next('[data-document-modal="open"]')

prevFileCard = ($fileCard) ->
  $fileCard.prev('[data-document-modal="open"]')

hasNextFileCard = ($fileCard) ->
  nextFileCard($fileCard).length > 0

hasPrevFileCard = ($fileCard) ->
  prevFileCard($fileCard).length > 0

fileExtension = (filename) ->
  String(filename).toLowerCase().split('.').pop()

isImage = (filename) ->
  IMAGE_EXTENSIONS.includes(fileExtension(filename))

isPDF = (filename) ->
  fileExtension(filename) == 'pdf'

createPreview = ($container, title, filename, url) ->
  switch
    when isImage(filename)
      $container.css 'height', 'auto'
      $container.append($('<img/>', { src: url, alt: filename }))
    when isPDF(filename) && PDFObject.supportsPDFs
      $container.css 'height', '100%'
      $pdfWrapper = $('<div/>').appendTo $container
      PDFObject.embed(url, $pdfWrapper, { width: '800px' })
    else
      $container.css 'height', 'auto'
      data = { title: title, filename: filename, url: url }
      $container.append(CamBackend.renderTemplate('noPreview', data))

findOrCreateDocumentModal = ->
  return $('<div/>').appendTo('body') unless $('body > .document-modal').first().length
  $('body > .document-modal').first()

initDocumentModal = ($fileCard) ->
  filename = $fileCard.data('document-name')
  title = $fileCard.prop('title')
  url = $fileCard.data('href')
  $modal = $(
    CamBackend.renderTemplate(
      'documentModal',
      filename: filename,
      title: title,
      url: url,
      hasNext: hasNextFileCard($fileCard),
      hasPrev: hasPrevFileCard($fileCard)
    )
  )
  $modal.find('.document-modal__next').on('click', -> initDocumentModal(nextFileCard($fileCard)))
  $modal.find('.document-modal__prev').on('click', -> initDocumentModal(prevFileCard($fileCard)))
  $modal.find('[data-toggle="tooltip"]').tooltip delay: show: 500

  createPreview($modal.find('.document-modal__content'), title, filename, url)
  findOrCreateDocumentModal().replaceWith($modal)
  $('body').addClass('no-scroll')

closeDocumentModal = ->
  findOrCreateDocumentModal().remove()
  $('body').removeClass('no-scroll')
  $(document).off 'keydown.document-modal'

$(document).on 'click', '[data-document-modal="open"]', (e) ->
  e.preventDefault()
  initDocumentModal($(e.currentTarget))
  $(document).on 'keydown.document-modal', (e) ->
    switch e.keyCode
      when 27 # esc
        closeDocumentModal()
      when 37 # left
        findOrCreateDocumentModal().find('.document-modal__prev').trigger 'click'
      when 39 # right
        findOrCreateDocumentModal().find('.document-modal__next').trigger 'click'


$(document).on 'click', '[data-document-modal="open"] .download-link', (e) ->
  e.stopPropagation()
  $(this).removeAttr('download') if navigator.userAgent.search('Firefox') > -1
  if window.navigator && window.navigator.msSaveOrOpenBlob
    e.preventDefault()
    xhr = new XMLHttpRequest()
    xhr.open('GET', this.href, true)
    xhr.responseType = 'blob'
    xhr.onload = (e) =>
      if (xhr.status == 200)
        blob = new Blob([xhr.response], type: xhr.getResponseHeader('content-type'))
        window.navigator.msSaveOrOpenBlob(blob, this.title)
    xhr.send()


$(document).on 'click', '[data-document-modal="close"]', (e) ->
  e.preventDefault()
  closeDocumentModal()

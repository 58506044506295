customEvent = document.createEvent('Event')
customEvent.initEvent('toggleVisibility', true, true)

selectIfElseForm = (element) ->
  $element = $ element
  value = if element.type == 'checkbox' then element.checked.toString() else $element.val()
  name = $element.data('name')
  $("[data-behaviour=\"if-else-form\"][data-name=\"#{name}\"]:not([data-type=\"source\"])").each (index, section) ->
    $section = $ section
    notVar = $section.data('not') == ''
    $parent = $section.parents('[data-behaviour="if-else-form"]:not([data-type=\"source\"])')
    check = value in ($section.data('value')?.toString() || '').split(',')
    if ((notVar && !check) || (!notVar && check)) && (!$parent.length || $parent.is(':visible'))
      $section.show().find('input,select,textarea').prop('disabled', false)
    else
      $section.hide().find('input,select,textarea').prop('disabled', true)
    $section.find('*').each (_, el) -> el.dispatchEvent(customEvent)

    # NOTE: http://silviomoreto.github.io/bootstrap-select/methods/#selectpickerrefresh
    if($.isFunction($().selectpicker))
      $section.find('select').selectpicker('refresh')
    $section.find('[data-behaviour="if-else-form"][data-type="source"]').each updateIfElseForm

updateIfElseForm = (index, element) ->
  $element = $ element
  return selectIfElseForm element if $element.is('input') && element.type == 'radio' && element.checked
  return selectIfElseForm element if $element.is('input') && element.type == 'checkbox'
  return selectIfElseForm element if $element.is('select')

init = ->
  $('[data-behaviour="if-else-form"][data-type="source"]').each updateIfElseForm

  $(document).on 'change', 'input[data-behaviour="if-else-form"][data-type="source"]', ->
    selectIfElseForm @ if (@type == 'radio' && @checked) || @type == 'checkbox'

  $(document).on 'change', 'select[data-behaviour="if-else-form"][data-type="source"]', ->
    selectIfElseForm @

if window.Turbolinks != undefined
  $(document).on 'turbolinks:load', init
else
  $(document).ready init

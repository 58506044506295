
const translations = {
  de: {
    actions: {
      cancel: 'Abbrechen',
      ok: 'Ok',
      close: 'Schliessen',
      download: 'Herunterladen'
    }
  },
  fr: {
    actions: {
      cancel: 'Annuler',
      ok: 'Confirmer',
      close: 'Fermer',
      download: 'Télécharger'
    }
  },
  en: {
    actions: {
      cancel: 'Cancel',
      ok: 'Confirm',
      close: 'Close',
      download: 'Download'
    }
  }
}

const detectLocale = () => $('html').prop('lang') || 'de'

export const translate = (scope, key) => {
  const locale = detectLocale()
  const localized = translations[locale] || {}
  const scoped = localized[scope] || {}
  return scoped[key] || "Missing translation: #{l}.#{scope}.#{key}"
}

window.CamBackend.translate = window.CamBackend.t = translate

checkSidebarImage = ->
  $sidebar = $('.sidebar')
  image_src = $sidebar.data('image')
  if image_src != undefined
    sidebar_container = '<div class="sidebar-background" style="background-image: url(' + image_src + ') "/>'
    $sidebar.append(sidebar_container)

# initSidebar = ->
#   ls = localStorage
#   isSidebarMinimized = JSON.parse(ls.getItem('isSidebarMinimized'))
#   $('body').addClass('sidebar-mini', isSidebarMinimized) if isSidebarMinimized

toggleSidebar = ->
  ls = localStorage
  isSidebarMinimized = JSON.parse(ls.getItem('isSidebarMinimized'))
  ls.setItem('isSidebarMinimized', !isSidebarMinimized)
  $('body').toggleClass 'sidebar-mini', !isSidebarMinimized

init = ->
  $.material.init()
  checkSidebarImage()

  # init sidebar menu
  # initSidebar()
  # $('#minimize-sidebar').on 'click', ->
  #   toggleSidebar()
  window.CamBackend.activateBootstrapSelect()
  window.CamBackend.activateDatePickers()

  # Setup focus classes on inputs
  $('.form-control').on('focus', ->
    $(this).parent('.input-group').addClass 'input-group-focus'
  ).on 'blur', ->
    $(this).parent('.input-group').removeClass 'input-group-focus'

  # For radio buttons and checkboxes marked with the required attribute, remove the has-error class from it's parent
  # form-group parent element, once it becomes checked.
  $('input[type="checkbox"][required], input[type="radio"][required]').on 'click', ->
    $(this).closest('.form-group').removeClass('has-error') if $(this).is(':checked')

if Turbolinks? then $(document).on('turbolinks:load', init) else $(init)

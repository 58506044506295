import template from 'lodash/template'
import isFunction from 'lodash/isFunction'

window.CamBackend.templates = {}
window.CamBackend.renderTemplate = (name, data) ->
  CamBackend.templates[name] = template(CamBackend.templates[name]) unless isFunction(CamBackend.templates[name])
  CamBackend.templates[name](data)

window.CamBackend.templates.confirmDialog = """
  <div class="modal fade bs-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="confirm">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"><%- title %></4>
        </div>
        <% if(body) { %>
          <div class="modal-body">
            <%= body.apply(this, arguments) %>
          </div>
        <% } %>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-simple" data-cancel-button>
            <%- textCancel %>
          </button>
          <button type="button" class="btn btn-primary" data-confirm-button>
            <%- textConfirm %>
          </button>
        </div>
      </div>
    </div>
  </div>
"""
